export default [
  {
    key: 'increasement',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'lotteryName',
    label: 'field.lotteryName',
  },
  {
    key: 'lotteryTime',
    label: 'field.lotteryTime',
  },
  {
    key: 'post',
    label: 'field.channel',
  },
  {
    key: 'digit',
    label: 'field.digit',
  },
  {
    key: 'maxWinAmount',
    label: 'field.maxWinAmount',
  },
  {
    key: 'actions',
    label: 'field.actions',
  },
];
